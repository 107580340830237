import { getEnviroment } from 'Sentry';

export default function getStaticUrl() {
  const enviroment = getEnviroment();
  if (enviroment === 'production')
    return 'https://i-con-public-static-content.s3.eu-central-1.amazonaws.com';
  if (enviroment === 'preproduction')
    return 'https://084828572049-i-con-public-static-content.s3.eu-central-1.amazonaws.com';
  return 'https://311141564260-i-con-public-static-content.s3.eu-central-1.amazonaws.com';
}
