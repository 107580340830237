import cookiesBM from './cookies';
import imprintBM from './imprint';
import privacyBM from './privacy';
import termsBM from './termsofuse';
import otConfig from './otConfig';
import ga from './ga';
import getStaticUrl from 'helpers/staticContent';

export default {
  ...ga,
  title: 'Back Market',
  landingImg: `${getStaticUrl()}/my-customer-space/Landing_BM.png`,
  favicon: `${getStaticUrl()}/my-customer-space/BM_favicon.ico`,
  keyLoginConfig: 'birthDate',
  langs: ['en', 'es', 'fr', 'de', 'it', 'nl'],
  defaultLang: 'en',
  faq: [
    { from: 1, to: 19, notShowed: [8, 13] },
    { from: 20, to: 28, notShowed: [21, 22, 23, 24, 25, 28] },
    { from: 29, to: 47 },
  ],
  cookies: cookiesBM,
  imprint: imprintBM,
  privacy: privacyBM,
  terms: termsBM,
  logo: {
    src: `${getStaticUrl()}/my-customer-space/Back_Market_Logo.svg`,
    alt: 'Back Market',
    width: '194.56px',
  },
  footerlogo: {
    src: `${getStaticUrl()}/my-customer-space/Back_Market_Logo_White.svg`,
    alt: 'Footer Back Market',
  },
  company: 'bolttech',
  otScript: otConfig,
  footer: [
    {
      label: 'terms_of_use',
      link: '/terms-of-use',
    },
    {
      label: 'imprint',
      link: '/imprint',
    },
    {
      label: 'faq',
      link: '/faq',
    },
  ],
};
