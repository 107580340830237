import cookiesBMUK from './cookies';
import imprintBMUK from './imprint';
import privacyBMUK from './privacy';
import termsBMUK from './termsofuse';
import otConfig from './otConfig';
import ga from './ga';
import getStaticUrl from 'helpers/staticContent';

export default {
  ...ga,
  title: 'Back Market UK',
  landingImg: `${getStaticUrl()}/my-customer-space/Landing_BM.png`,
  favicon: `${getStaticUrl()}/my-customer-space/BM_favicon.ico`,
  keyLoginConfig: 'birthDate',
  langs: ['en'],
  defaultLang: 'en',
  faq: [
    { from: 1, to: 19, notShowed: [4, 8, 13] },
    { from: 20, to: 38 },
  ],
  cookies: cookiesBMUK,
  imprint: imprintBMUK,
  privacy: privacyBMUK,
  terms: termsBMUK,
  logo: {
    src: `${getStaticUrl()}/my-customer-space/Back_Market_Logo.svg`,
    alt: 'Back Market',
    width: '194.56px',
  },
  footerlogo: {
    src: `${getStaticUrl()}/my-customer-space/Back_Market_Logo_White.svg`,
    alt: 'Footer Back Market',
  },
  company: 'bolttech',
  otScript: otConfig,
  footer: [
    {
      label: 'terms_of_use',
      link: '/terms-of-use',
    },
    {
      label: 'imprint',
      link: '/imprint',
    },
    {
      label: 'faq',
      link: '/faq',
    },
  ],
};
