import cookiesRF from './cookies';
import imprintRF from './imprint';
import privacyRF from './privacy';
import termsRF from './termsofuse';
import otConfig from './otConfig';
import getStaticUrl from 'helpers/staticContent';

export default {
  title: 'Refurbed',
  landingImg: `${getStaticUrl()}/my-customer-space/Landing_RF.png`,
  favicon: `${getStaticUrl()}/my-customer-space/RF_favicon.ico`,
  keyLoginConfig: 'birthDate',
  langs: ['de', 'it'],
  defaultLang: 'de',
  faq: [
    { from: 1, to: 14 },
    { from: 17, to: 32 },
  ],
  cookies: cookiesRF,
  imprint: imprintRF,
  privacy: privacyRF,
  terms: termsRF,
  logo: {
    src: `${getStaticUrl()}/my-customer-space/Refurbed_Logo.svg`,
    alt: 'Refurbed',
    height: '40px',
  },
  footerlogo: {
    src: `${getStaticUrl()}/my-customer-space/Refurbed_Logo_White.svg`,
    alt: 'Footer Refurbed',
  },
  company: 'bolttech',
  otScript: otConfig,
  footer: [
    {
      label: 'terms_of_use',
      link: '/terms-of-use',
    },
    {
      label: 'imprint',
      link: '/imprint',
    },
    {
      label: 'faq',
      link: '/faq',
    },
  ],
};
